<template>
  <div :class="'container big-screen ' + 'digital-park'">
    <div class="container-wrap">
      <h5 class="container-title" @click="toggleFullScreen">
        中机联设备可视化大屏
        <span class="time-num" @click.stop="languge">{{ timerNum }}</span>
      </h5>
      <router-view></router-view>
      <div class="container-tab">
        <containerTab />
      </div>
    </div>

    <baiduMapComponent />
  </div>
</template>
<script>
import Vue from 'vue'
import { Dialog, MessageBox } from 'element-ui'
Vue.use(Dialog)

import containerTab from '../../components/container-tab.vue'
import digitalPark from './children/digital-park.vue'
import screenfull from 'screenfull'
import baiduMapComponent from  './modules/baidu-map-component.vue'

export default {
  name: 'zhanyu',
  components: {
    containerTab,
    digitalPark,
    baiduMapComponent
  },
  provide() {
    return {
      app: this,
    }
  },
  data() {
    return {
      dialogVRVisible: false, // vr弹窗
      screenfull: null,
      dialogFormVisible: false,
      map: null, // 百度地图引用
      // areaInfo: {}, // 地图详情数据
      timerNum: this.$.formatDate(new Date(), 'yyyy-MM-dd hh:mm:ss'),
    }
  },
  methods: {
    languge() {
      this.$router.push(window.translate.language.getCurrent() === 'chinese_simplified' ? './zjl' : './overview')
      setTimeout(() => {

        window.translate.changeLanguage(window.translate.language.getCurrent() == 'chinese_simplified' ? 'english' : 'chinese_simplified')
      }, 100)
    },
    toVr(url) {
      console.log('toVr', url)
      this.dialogVRVisible = true
      this.vrUrl = url
    },
    // 右上角时间
    loadTimer() {
      this.timerNum = this.$.formatDate(new Date(), 'yyyy-MM-dd hh:mm:ss')
      setTimeout((_) => {
        this.loadTimer()
      }, 1000 * 1)
    },
    toggleFullScreen() {
      screenfull && screenfull.toggle()
      // screenfull.isFullscreen && location.reload()
    },
    fontHandle(num, unit) {
      let docEl = document.documentElement
      let clientWidth = docEl.clientWidth < 1920 ? 1920 : docEl.clientWidth
      if (!clientWidth) return
      let remUnit = 100
      let designWidth = 2560

      let standardFontSize = (clientWidth / (designWidth / remUnit)) * 1
      let endNum = standardFontSize * (num / 100).toFixed(2) * 1
      return unit ? endNum + unit : endNum
    },
  },
  mounted() {
    // screenfull.isEnabled && !screenfull.isFullscreen && screenfull.toggle()
    // console.log('screenfull', screenfull, screenfull.isEnabled , !screenfull.isFullscreen)
    if(screenfull.isEnabled && !screenfull.isFullscreen && !localStorage.getItem('auto')){

      // MessageBox.confirm('是否开启全屏？（或点击大标题可切换全屏）', '提醒', {
      //     confirmButtonText: '确定',
      //     cancelButtonText: '取消',
      //     closeOnClickModal: false
      //     // callback: action => {
      //     // }
      //   }).then(() => {
      //     screenfull.toggle()
      //     localStorage.setItem('auto', 1)
      //   });
    }
  },
  created() {
    this.loadTimer()
    document.title = '中机联'
  },
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
